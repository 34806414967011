import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql, Link } from 'gatsby';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { Layout, SEO } from '../components';
import DocumentsList from '../components/documents/DocumentsList';
import Clouds from '../assets/images/clouds.svg';
import FolderIcon from '../assets/icons/folder.svg';
import ChevronIcon from '../assets/icons/chevron.svg';
import PlusIcon from '../assets/icons/plus.svg';
import LockIcon from '../assets/icons/lock.svg';

import { CountdownTimer } from '../components/UI/FlipClock';

const ModuleSubCategoryPage = ({
  data,
  data: {
    subCategoryOption: { edges },
    subCategoryPage: { module, heading: subPageCategoryHeading },
    timers
  },
}) => {
  process.env.NODE_ENV == 'development' &&
    console.log('🚀 module-sub-category-sub-category-options.js', data);
  const {
    id,
    description,
    subCategoryHeading,
    featuredDocument,
    subCategoryOptionsList,
    subCategoryOptions,
    seo,
    seoKeywords,
  } = edges[0].node;

  const orderedTimers = React.useMemo(() => {
    return timers?.group?.map(({ edges, examBoard }) => {
      const groups = {}
      for (const { node, node: { title } } of edges) {
        const regex = /\d([A-Za-z])(?![A-Za-z])/;
        const matched = title?.match(regex);

        if (!matched) {
          if (!groups.unmatched) groups.unmatched = [];
          groups.unmatched = [
            ...groups.unmatched,
            node
          ];
          continue;
        };

        const [_, letter] = matched;
        if (!groups[letter]) {
          groups[letter] = [];
        }
        groups[letter].push(node);
      }
      const formattedEdges = Object.values(groups).map(papers => papers.sort((a, b) => b.title - a.title)).flat();
      return {
        edges: formattedEdges,
        examBoard
      }
    })
  }, [timers]);

  const numOfTimers = orderedTimers.reduce((total, { edges }) => total += edges?.length ?? 0, 0);

  const renderSubCategoryOptions = (optionHeaderSlug, subCategoryOption) => {
    const isLocked = subCategoryOption.isLocked;
    const lockedLink = isLocked ? `${subCategoryOption.slug}/locked` : subCategoryOption.slug;
    const link = optionHeaderSlug ? `${optionHeaderSlug}/${lockedLink}` : lockedLink;
    return (
      <li className="category-option" key={subCategoryOption.id}>
        <img src={FolderIcon} className="category-option-image" alt="Folder" />
        <div className="category-option-text">
          <Link to={link} className="category-option-link">
            {subCategoryOption.name}
          </Link>
        </div>
        {isLocked && <img src={LockIcon} className="lock-icon" alt="Locked" />}
      </li>
    );
  };

  const renderSubCategory = (subCategory, index) => {
    return (
      <>
        {renderSubCategoryOptions(null, {
          isLocked: false,
          slug: subCategory?.slug,
          name: subCategory?.name,
        })}
      </>
    );
  };

  const newSubCategoryOptions = subCategoryOptions.map(
    ({ subCategoryOptionHeading }) => subCategoryOptionHeading,
  );

  return (
    <MathJaxContext>
      <Layout>
        <SEO
          description={seo && seo.description}
          title={seo && seo.title}
          image={seo && seo.image}
          seoKeywords={seoKeywords}
        />
        <div>
          <div className="container">
            <div className={`srow mobile:x-centered ${numOfTimers < 4 ? 'x-centered' : ''}`}>
              {!!orderedTimers?.length &&
                orderedTimers.map(({ edges, examBoard }, i) =>
                  !!edges?.length && (
                    <div key={`wrapper-${i}`}>
                      {examBoard !== 'No exam board' && (
                        <p className="1.25 bold mb-1">{examBoard}</p>
                      )}
                      {edges.map(({ endDate, title, daysOnly }, k) => (
                        <div className="column narrow mb-1" key={`clock ${k}`}>
                          <CountdownTimer
                            endDate={endDate}
                            title={title}
                            daysOnly={daysOnly}
                          />
                        </div>
                      ))}
                    </div>
                  )
                )
              }
            </div>
            <div className="w-layout-grid grid hero">
              <div className="content-block">
                <div style={{ marginBottom: '5px' }}>
                  <a className="page-breadcrums" href={`/modules/${module.slug}`}>
                    {module.title}
                  </a>
                  {' > '}
                  <a
                    className="page-breadcrums"
                    href={`/modules/${module.slug}/${subPageCategoryHeading.slug}`}
                  >
                    {subPageCategoryHeading.name}
                  </a>
                </div>
                <h1 className="content-h2">{subCategoryHeading[0].name}</h1>
                <br />
                <MathJax hideUntilTypeset="first">
                  <ReactMarkdown className="paragraph" children={description} />
                </MathJax>
              </div>
              <img src={module.image.url} alt={module.image.alt} />
            </div>
          </div>
          <img src={Clouds} alt="Clouds pattern" className="pattern-left" />
        </div>
        <article className="content-section">
          <div className="content-block container">
            {featuredDocument.length > 0 && (
              <div>
                <br />
                {<DocumentsList documents={featuredDocument} />}
              </div>
            )}

            <div>
              <br />
              <ul className="category-options">{newSubCategoryOptions.map(renderSubCategory)}</ul>
            </div>
            {/* <div className={categoryCssClasses}>{subCategoryList.map(renderSubCategory)}</div> */}
          </div>
        </article>
      </Layout>
    </MathJaxContext>
  );
};

export default ModuleSubCategoryPage;

const query = graphql`
  query subCategoryPageQueryAndSubCategoryPageOptionsQuery(
    $subCategoryId: String!
    $subCategoryPageId: String!
    $pathName: String
  ) {
    timers: allDatoCmsCountdownTimer(filter: { path: { eq: $pathName } }, sort: { fields: title }) {
      group(field: examBoard) {
        edges {
            node {
            daysOnly
            endDate
            examBoard
            path
            title
            }
        }
        examBoard: fieldValue
      }
    }
    subCategoryPage: datoCmsModuleSubCategoryPage(id: { eq: $subCategoryPageId }) {
      module {
        title
        slug
        image {
          alt
          url
        }
      }
      heading {
        name
        slug
      }
    }
    subCategoryOption: allDatoCmsSubCategory(filter: { id: { eq: $subCategoryId } }) {
      edges {
        node {
          id
          description
          subCategoryHeading {
            id
            name
            slug
          }
          featuredDocument {
            id: originalId
            name
            freeDocument
            freeSample
            hasPassword
            fileUrl
            originalId: id
          }
          subCategoryOptions {
            subCategoryOptionHeading {
              id
              name
              slug
            }
          }
          seo {
            description
            title
            image {
              url
            }
          }
          seoKeywords {
            keyword
          }
        }
      }
    }
  }
`;

export { query };
